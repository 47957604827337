import { ArticlePageNode } from '../pages/insights'

type Sector = {
  slug: String
}

type Languages = {
  slug: String
}

interface UrlTypes extends ArticlePageNode {
  sectors?: Sector
  articleLang?: Languages
}

const resolvedUrlPrefix = (node: UrlTypes) => {
  const sectorName = node?.sectors ? node?.sectors[0]?.slug : ''
  const languagePrefix = node?.articleLang && node?.articleLang[0]?.slug
  const resolveSector = (sectorName: string) => {
    const s = sectorName
    return s.startsWith('sf-') ? s.replace('sf-', '') : s
  }

  const prefix = resolveSector(sectorName)
  if (languagePrefix && languagePrefix !== 'en' && prefix) {
    return `${languagePrefix}/${prefix}/${node.slug}`
  }
  if (prefix) {
    return `${prefix}/${node.slug}`
  }
  return `${node.slug}`
}

export default resolvedUrlPrefix
