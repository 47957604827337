import React, { useContext } from 'react'
import queryString from 'query-string'
import { useLocation } from '@reach/router'
import { graphql, Link } from 'gatsby'
import {
  InsightsLinkProps,
  SFHero,
  dateToTimestampMidnightET
} from 'suf_storybook'
import { Query } from '../generated/gatsby.types'
import { OverlayContext } from '../components/OverlayContext'
import InsightsResultsWrapper from '../components/insightsResultsWrapper'
import Main from '../components/main'
import SEO from '../components/seo'

import '../../static/_css/insights-listing-hero.css'
import { parseBannerProps } from '../lib'
import resolvedUrlPrefix from '../lib/resolvedUrlPrefix'

type Props = {
  data: Query
}

const parseQueryParams = () => {
  const location = useLocation()
  const { search } = location
  const queryParams = queryString.parse(search)
  return queryParams.reportType
}

type ArticleType = {
  slugPrefix: string
  subtitle: string
}

export type ArticlePageNode = {
  abstract?: {
    abstract: string
  }
  articleType: ArticleType[]
  dateTime?: string
  slug: string
  title: string
  languages: {
    title: string
    slug: string
  }
}

type ArticlePageEdge = {
  node: ArticlePageNode
}

const Insights = ({ data }: Props) => {
  const reportype =
    parseQueryParams() || 'insights/sustainable-insight,insights/commentary,insights/esg-rating,insights/second-party-opinion'
  const { hasMarketo } = useContext(OverlayContext)
  const {
    allContentfulArticlePage: { edges },
    contentfulBanner: banner
  } = data

  const RenderLink = ({ className = '', title, slug }: InsightsLinkProps) => (
    <Link className={className} to={slug}>
      {title}
    </Link>
  )
  return (
    <Main isInsights>
      <SEO
        title="Insights :: Sustainable Fitch"
        description="Insights page"
        type="gatsby"
      />
      {/* @TODO remove the div below after release */}
      <div id="insightsBanner">
        <SFHero
          {...parseBannerProps(banner)}
          hasMarketo={hasMarketo}
          isActionBarOpen={false}
        />
      </div>

      <section className="section section--spacing-below">
        <div className="wrapper--1">
          <InsightsResultsWrapper
            items={edges
              .filter(
                ({ node }: ArticlePageEdge) =>
                  !node.title.toLowerCase().startsWith('prototype')
              )
              .map(({ node }: ArticlePageEdge) => {
                const { subtitle } = node.articleType[0]

                return {
                  articleType: {
                    subTitle: subtitle
                  },
                  publishedDate: dateToTimestampMidnightET(node.dateTime),
                  slug: `/${resolvedUrlPrefix(node)}`,
                  abstract: node.abstract?.abstract,
                  title: node.title,
                  languages: node.languages
                }
              })}
            renderLink={RenderLink}
            reportType={reportype}
          />
        </div>
      </section>
    </Main>
  )
}

export const query = graphql`
  query Insights {
    allContentfulArticlePage(
      filter: {
        node_locale: { eq: "en-US" }
        metadata: { tags: { elemMatch: { name: { eq: "Sustainable Fitch" } } } }
      }
      sort: { fields: [dateTime], order: DESC }
    ) {
      edges {
        node {
          abstract {
            abstract
          }
          articleType {
            slugPrefix
            subtitle
          }
          dateTime
          id
          slug
          sectors {
            slug
          }
          languages {
            title
            slug
          }
          articleLang: languages {
            slug
          }
          title
        }
      }
    }
    contentfulBanner(description: { eq: "SF Insights Banner" }) {
      ...ContentfulBannerFragment
    }
  }
`

export default Insights
